<template>
  <div v-if="!$store.state.app.isLoadingPage">
    <v-row>
      <v-col
        cols="12"
        md="12"
        sm="12"
        order="1"
        class="align-self-end"
      >
        <h3>Dashboard</h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import store from '@/store'

export default {
  data() {
    return {
      perfil: {},
    }
  },
  mounted() {
    this.perfil = this.$route.params.perfil
    this.cargaPerfil()
    this.$watch(
      () => this.$route.params,
      // eslint-disable-next-line no-unused-vars
      (toParams, previousParams) => {
        // store.state.app.cargandoMenu = true
        this.perfil = toParams.perfil

        // sessionStorage.setItem('perfil', toParams.perfil)
        // store.commit('getPerfilesAuth')
        // setTimeout(() => {
        //   store.state.app.cargandoMenu = false
        //   window.location.reload()
        // }, 800)
      },
    )
  },
  methods: {
    cargaPerfil() {
      // console.log(store.state.app.userProfile)
      setTimeout(() => {
        store.state.app.isLoadingPage = false
      }, 800)
    },
  },
}
</script>
